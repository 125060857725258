import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'
import styled from 'styled-components'
import { Box } from './Shared'

const Wrapper = styled(Box)`
  button {
    margin-right: 0.5rem;
  }

  svg {
    g {
      stroke: rgba(0, 0, 0, 0.5);
    }
    rect {
      fill: ${({ theme }) => theme.colors.toreaBay};
    }

    path {
      fill: white;
    }

    html {
      background-color: gray;
    }
  }
`

export const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <Wrapper>
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
      <RedditShareButton url={url} title={title}>
        <RedditIcon size={40} />
      </RedditShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>
    </Wrapper>
  )
}
export default ShareButtons
