import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { ShareButtons } from '../../components/ShareButtons'
import { Box, Column, Heading, Span, Text } from '../../components/Shared'
import { camelise } from '../../utils/camelise'
import { formatDate } from '../../utils/dateFormatter'

const PostContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;

  *:not(.inline-gatsby-image-wrapper) {
    max-width: 800px;
  }

  span[class^=' wp-image'] {
    display: block;
    height: 500px;
  }

  iframe {
    height: 500px;
    width: 50vw;
  }

  p,
  li {
    line-height: 2;
    letter-spacing: 0.06rem;
    margin: 0.75rem 0;
    color: ${({ theme }) => theme.colors.nandor};
  }

  p:first-of-type {
    margin-top: 3rem;
  }

  li {
    counter-increment: item;
    margin-left: 2rem;
  }

  li:before {
    margin-right: 10px;
    content: counter(item);
    background: ${({ theme }) => theme.colors.toreaBay};
    border-radius: 100%;
    color: white;
    width: 1.9em;
    text-align: center;
    display: inline-block;
  }

  ol,
  ul {
    margin: 1.5rem 0;
    list-style: none;
    counter-reset: item;
  }

  em,
  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2rem 0;
    color: #ca973b !important;
  }

  h6 {
    font-size: 1rem;
    color: #ca973b !important;
  }

  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }

  svg,
  a {
    max-height: 10rem;
  }

  .inline-gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
  }
`

const Post = ({ pageContext }) => {
  const {
    post: { title, content, actualPostImage, uri, categories, date, tags, seo },
  } = pageContext
  const metaDesc = seo?.metaDesc

  const {
    wpMisc: {
      pageBuilder: { layouts },
    },
  } = useStaticQuery(graphql`
    query fetchBlogPostAbout {
      wpMisc {
        pageBuilder {
          layouts {
            ... on WpMisc_Pagebuilder_Layouts_BlogPostAbout {
              fieldGroupName
              copy
              avatar {
                databaseId
                modified
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: TRACED_SVG
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { copy, avatar } = layouts[2]

  const postTags = tags.nodes.map((tag) => camelise(tag.name))

  return (
    <Layout>
      <Box as="article">
        <SEO
          title={title}
          description={metaDesc || ''}
          path={uri}
          image={actualPostImage}
        />
        <Column as="section" bg="toreaBay" color="white" height="40vh">
          <Span fontSize={5} fontWeight={700}>
            {categories?.nodes[1]?.name || 'ALL CATEGORIES'}
          </Span>
          <Heading
            color="white"
            fontSize={8}
            width="50%"
            textAlign="center"
            my={3}
          >
            {title}
          </Heading>
          <Span fontSize={2} fontWeight={700}>
            {formatDate(date)}
          </Span>
        </Column>
        <PostContent
          p={4}
          as="section"
          margin="0 auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="toreaBay"
          height="10vh"
          p={2}
        >
          <Span color="white" fontSize={3} mb={2}>
            Share:
          </Span>
          <ShareButtons
            title={title}
            url={`www.emancopyco.com/blog${uri}`}
            twitterHandle="emancopyco"
            tags={postTags}
          />
        </Box>
        <Box
          as="article"
          display="flex"
          flexDirection={['column', 'column', 'row']}
          justifyContent="center"
          alignItems="center"
          bg="white"
          maxWidth="1100px"
          margin="0 auto"
          height={['auto', 'auto', '250px']}
          py={4}
        >
          <FluidImage
            image={avatar}
            style={{
              borderRadius: '50%',
              width: '45vw',
              maxWidth: '150px',
            }}
          />
          <Text width="50%" ml={[0, 0, '3rem']} mt={[3, 3, 0]}>
            {copy}
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default Post
